*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  background: #eee;
  color: #121212;
  height: 100%;
  font: normal 120% / 1.5 'IBM Plex Sans', sans-serif;
  overflow: hidden;
}

html, body {
  height: inherit;
}

body {
  font-size: 1rem;
}

header,
footer {
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
}

footer {
  background: #fff;
  bottom: 0;
  font-size: .8rem;
  padding: .2rem;
  text-align: right;
}

@media (min-width: 50rem) {
  footer {
    padding: 0 .2rem;
    right: 0;
    position: fixed;
    z-index: 1;
  }
}

footer p {
  margin: 0;
}

#app {
  background: url(../img/loader.svg) center no-repeat;
  background-size: 10rem auto;
}

h1 {
  background: #fff;
  display: inline-block;
  font-size: .8rem;
  margin: 0;
  padding: .5rem .8rem .5rem .5rem;
  text-transform: uppercase;
}

a {
  color: #2196f3;
  padding: 0 4px;
}

.component-palette {
  align-content: center;
  background-color: #eee;
  display: flex;
  height: 15rem;
  width: 100%;
}

@media (min-width: 50rem) {
  .component-palette {
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 0;
  }
}

.component-swatch-holder {
  height: 100%;
  flex: 1 1 100%;
  position: relative;
}

.component-copy {
  background: rgba(255,255,255,1);
  bottom: 0;
  border: 0;
  cursor: pointer;
  font-family: 'IBM Plex Mono', monospace;
  height: 2rem;
  left: 0;
  margin: auto auto 5rem auto;
  padding: 2px .5rem;
  position: absolute;
  right: 0;
  z-index: 2;
  width: max-content;
}

.component-copy b {
  position: absolute;
  left: -9999rem;
}

@media (min-width: 1000px) {
  .component-copy {
    box-shadow: 0 0 20px -6px #000;
  }
  .component-copy b {
    position: static;
  }
  .component-copy span {
    position: absolute;
    left: -9999rem;
  }
}

.component-swatch {
  display: block;
  width: 100%;
  height: 100%;
}

.component-swatch > rect {
  height: 100%;
  width: 100%;
}

select {
  -webkit-appearance: none;
  appearance: none; 
  background: rgba(255, 255, 255, .9) url(../img/chevron.svg) right 1rem center no-repeat;
  background-size: 1rem auto;
}

select::-ms-expand {
  display: none;
}

input, select, button {
  border-radius: 3px;
  border: 1px solid hsl(204, 12%, 65%);
  font-family: inherit;
  font-size: 1rem;
  padding: .5rem;
}

[type=text],
select {
  width: 100%;
}

[type=text] {
  background: rgba(255, 255, 255, .9);
  border-radius: 2px 0 0 2px;
}

button {
  border-color: hsl(204, 12%, 25%);
  cursor: pointer;

}


.input-button-wrap {
  flex: 0 0 100%;
  margin: .5rem 0 1rem;
  padding: 0 .5rem;
}

@media (min-width: 50rem) {
  .input-button-wrap {
    flex: 1 1 140px;
    margin: .75rem 0;
  }
}

.input-button {
  background: hsl(204, 12%, 25%);
  color: whitesmoke;
  margin: 1px 0;
  padding: .5rem 1rem;
  white-space: nowrap;
  width: 100%;
}

.input-button:hover {
  background: hsl(204, 12%, 40%);
  border-color: hsl(204, 12%, 40%);
}

@media (min-width: 50rem) {
  .input-button {
    margin: -1px 0 0;
    width: auto;
  }
}

[id=controls] {
  background: rgba(255,255,255,.98);
  display: flex;
  position: relative;
  z-index: 1;
}

[id=controls] > div:last-child {
  display: flex;
  align-items: center;
}

@media (max-width: 50rem) {
	[id=controls] {
    flex-wrap: wrap;
  }
}

.input-color-linked {
  flex: 1 1 100%;
  margin: 0 0 .5rem;
  padding: .5rem;
  transition: background 250ms ease-in;
}

@media (min-width: 50rem) {
	.input-color-linked {
    flex-basis: 40%;
    margin: 0;
  }
}

.input-color-linked--has-error {
  background: hsl(12, 100%, 90%);
}

.input-color-linked-group {
  display: flex;
  align-items: stretch;
}

.input-color-linked-group > div:first-child {
  flex: 1 1 80%;
}

.input-color-linked-group label {
  position: absolute;
  left: -9999em;
}

.input-color-linked-text {
  flex: 1 1 80%;
}

.input-notice {
  font-size: .8rem;
  font-style: italic;
  color: #444;
  margin: 0 .2rem;
}

fieldset {
  border: 0;
  padding: 0;
}

legend {
  padding-bottom: .25rem;
  font-weight: bold;
}

[type=color] {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 3rem;
  padding: 0;
  height: 100%;
}

:is(a, input, select, button):focus {
  outline: 1px solid rgb(51,153,255);
  box-shadow: 0 0 3px 4px rgb(51,153,255);
}

.input-select {
  margin-bottom: .5rem;
  padding: .5rem;
  flex: 0 1 100%;
}


@media (min-width: 50rem) {
  .input-select {
    padding: .5rem;
    flex-basis: 40%;
  }
}

label {
  display: block;
  font-weight: bold;
  padding-bottom: .25rem;
}

@media (min-width: 50rem) {
  .input-button {
    margin: -10px 0 0 0;
    width: auto;
  }

  button {
    padding: .5rem 1rem;
  }
}

.error-message:not([hidden]) {
  color: hsl(12, 100%, 30%);
  margin: 0 .2rem;
}

input[type="color" i]::-webkit-color-swatch {
  padding: 0;
  margin: -4px -2px;
}

input[type="color" i]::-webkit-color-swatch {
  border: 1px solid transparent;
}

input[type="color"]:-moz-system-metric(color-picker-available) {
  border: 1px solid transparent;
  margin: -1px -1px 0;
  padding: 0;
  block-size: 60px;
  inline-size: 60px;
}


.modal-block::before {
  background: hsla(204, 12%, 25%, .75);
  content: '\00a0';
  display: block;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.modal-block-content {
  background: #fff;
  border-radius: .3rem;
  height: 90%;
  margin: 2rem auto;
  padding: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  z-index: 2;
}

.modal-block-content-overflow {
  height: 100%;
  margin: 0 3rem 0 1rem;
  overflow-y: auto;
}

.modal-block-content-overflow h1,
.modal-block-content-overflow h2 {
  margin-top: 0;
}

.modal-button-close {
  background: transparent;
  border: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  height: 2rem;
  width: 2rem;
}

.modal-button-close:focus circle,
.modal-button-close:hover circle {
  fill: rgba(62, 70, 76, .75);
}

.modal-button-close svg {
  display: block;
  height: 100%;
  width: 100%;
}

.button-stripped {
  background: transparent;
  border: 0;
  height: auto;
  padding: .3rem 0;
}

.button-modal-trigger {
  color: #2196f3;
  cursor: pointer;
  font-size: .8rem;
  text-decoration: underline hsl(117, 70%, 54%);
  text-decoration-skip: ink;
}

dl {
  max-width: 550px;
}

dt {
  font-weight: bold;
  font-size: 1.1rem;
}

dd {
  margin-left: 0;
}

dd + dt {
  margin-top: 1rem;
}
